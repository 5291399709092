export default {
    name: "Error 404",
    data() {
        return {
        }
    },
    created() {
        
    },

}