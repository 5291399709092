<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="error-page-wraper">
        <div class="error-body">
          <div id="error-main">
            <div class="fof">
              <h1>404 Page not found!!!</h1>
              <a href="javascript:void(0)" @click="$router.push('/home')"><h3 style="color: green;">back to home?</h3></a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/error404.js"></script>

<style scoped>
@import "../css/error404.css";
</style>